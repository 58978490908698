<template>
    <modal :id="id" title="Interview Style Podcast" :closable="closable">
        <a-alert type="warning">
            This will remove current content and will generate 20 topics on
            basis of which the system will create a transcript. Do you want to
            proceed?
        </a-alert>

        <template #actions>
            <v-btn :disabled="generating" @click="onCancel">Cancel</v-btn>

            <v-spacer />

            <v-btn color="primary" :loading="generating" @click="onConfirm">
                Generate Topics
            </v-btn>
        </template>
    </modal>
</template>

<script lang="ts">
import Vue from 'vue';
import Component from 'vue-class-component';

import { Modal } from '@/components/Modal';
import { AAlert } from '@/components/AAlert';

const GenerateTopicsModalProps = Vue.extend({
    props: {
        generating: {
            type: Boolean,
            default() {
                return false;
            }
        }
    }
});

@Component({
    components: {
        Modal,
        AAlert
    }
})
export default class GenerateTopicsModal extends GenerateTopicsModalProps {
    id = 'GenerateTopicsModal';

    closable = false;

    onCancel() {
        this.$emit('cancel');

        this.$store.dispatch('modal/close', this.id);
    }

    onConfirm() {
        this.$emit('confirm');
    }
}
</script>
